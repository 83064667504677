
import anime from 'animejs/lib/anime.es.js';

const $ = require("jquery");



document.zeroscen = function () {

    setTimeout(function () {

        var tl = anime.timeline({
            easing: 'easeOutExpo',
            duration: 750
        });
        tl

                .add({
                    targets: '.mainnavi div',

                    visibility: 'visible',
                    opacity: 0,

                    easing: "easeOutExpo",
                    duration: 1,

                })

                .add({
                    targets: 'footer',
                    visibility: 'none',
                    opacity: 0,
                    width: '0',
                    easing: "easeOutExpo",
                    duration: 1,
                })




                .add({
                    targets: '#imgslogan',

                    duration: 1,
                    delay: 1,
                    width: 0,
                    opacity: 1,
                })
                .add({
                    targets: '.mainnaviBackground',
                    opacity: 1,
                    bottom: 0,
                    easing: "easeOutExpo",
                    duration: 222,
                    delay: 1
                })


                .add({
                    targets: '.blackBackground',
                    opacity: 0.05,
                    easing: "easeOutExpo",
                    duration: 444,
                    delay: 1,
                })






                ;
    }, 15);


}


document.introstart = function () {





    setTimeout(function () {

        try {

            if (document.location.search.indexOf('backStop') > -1 || true) {
                $('#header444').css("animation", 'none');
            }

            if (document.location.search.indexOf('MiniNavFull') > -1 || true) {
                $('.section444').css("width", 'auto');
            }


            if (document.location.search.indexOf('mauseMove') > -1) {
                $('#header444').css("background-position-y", 'calc(var(--mouse-y, 0) * 150%)');
                $('#header444').css("background-position-x", 'calc(var(--mouse-x, 0) * 50%)');
            }

            if (document.location.search.indexOf('mauseMove') > -1) {
                $('#header444').css("background-position-y", 'calc(var(--mouse-y, 0) * 150%)');
                $('#header444').css("background-position-x", 'calc(var(--mouse-x, 0) * 50%)');
            }

            if (document.location.search.indexOf('logoStop') > -1 || true) {
                $('#imgslogan').css("transform", 'none');

            }
            if (document.location.search.indexOf('backX') > -1) {
                $('#header444').css('animation', 'animatedBackground 150s linear infinite;');
                $('#header444').css('-moz-animation', ' animatedBackground 150s linear infinite;');
                $('#header444').css('-webkit-animation', 'animatedBackground 150s linear infinite;');
                $('#header444').css('-ms-animation', 'animatedBackground 150s linear infinite;');
                $('#header444').css('-o-animation', 'animatedBackground 150s linear infinite;');
            }
            $('#header444').css("margin-top", '0');



        } catch (error) {
        }
    }, 15);











//
    setTimeout(function () {
        try {


//            var textWrapper = document.querySelector('.ml2');
//            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
//            textWrapper.innerHTML = textWrapper.textContent.replace(/\S+/g, " <span class='letter'>$&</span> ");
//            var textWrapper = document.querySelector('.ml3');
//            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
////            textWrapper.innerHTML = textWrapper.textContent.replace(/\S+/g, " <span class='letter'>$&</span> ");
//
//            var textWrapper = document.querySelector('.ml7');
//            textWrapper.innerHTML = textWrapper.textContent.replace(/\S+/g, "<span class='letter'>$&</span>");
//
//



//            var slogan444 = document.querySelector('.slogan444');
//            slogan444.classList.remove("fadeInmw");
//            slogan444.classList.add("is-visible");



//            var t2 = anime.timeline({
//                easing: 'easeOutExpo',
//                duration: 750
//            });
//            t2
//                    .add({
//                        targets: '.ml',
//                        scale: 1.1,
//
//                        easing: "easeOutExpo",
//                        duration: 2250,
//                        delay: 1
//                    })






// Create a timeline with default parameters
            var tl = anime.timeline({
                easing: 'easeOutExpo',
                duration: 750
            });


//                    .add({
//                        targets: '.ml2',
//                        opacity: 0,
//                        duration: 1,
//                    })


            tl
                    .add({
                        targets: '.ml3',
                        opacity: 1,
                        duration: 1,
                    })

//                    .add({
//                        targets: '.ml7',
//                        opacity: 1,
//                        duration: 1,
//                    })








//                    .add({
//                        targets: '#nav',
//                        opacity: [1, 0.5],
//
//                        easing: "easeOutExpo",
//                        duration: 1,
//
//                    })




//                    .add({
//                        delay: 999
//                    })


//                    .add({
//                        targets: '.ml',
//                        scale: [1.0, 0.5],
//                        opacity: [1, 0],
//                        height: 0,
//                        marginBottom: 0,
//                        paddingTop: 165,
//                        translateZ: 0,
//                        easing: "easeOutExpo",
//                        duration: 950,
//                        delay: 999
//
//                    })



                    ;
            if (document.location.search.indexOf('NapisyTak') > -1)
            {
                tl
                        .add({
                            targets: '.ml2',
                            opacity: 1,
                            duration: 1,
                        })

                        .add({
                            targets: '.ml2 .letter',
                            scale: [4, 1],
                            opacity: [0, 1],
                            translateZ: 0,
                            easing: "easeOutExpo",
                            duration: 950,
                            delay: (el, i) => 70 * i * 3
                        })

                        .add({
                            targets: '.ml2 .letter',
                            scale: 0.5,
                            opacity: 0,
                            translateZ: 0,
                            easing: "easeOutExpo",
                            duration: 444,
                            height: 0,
                            marginBottom: 0,
                            delay: 1,
                        })
                        .add({
                            targets: '.ml2',
                            height: 0,
                            marginBottom: 0,
                            delay: 1,
                            duration: 1
                        });
            }




            var mainnavi_div_scale;
            if (window.screen.width > 767) {
                mainnavi_div_scale = 1.03;
            } else {
                mainnavi_div_scale = 1;
            }

            tl


//
//
//                    .add({
//                        targets: '.ml3 .letter',
//                        scale: [4, 1],
//                        opacity: [0, 1],
//                        translateZ: 0,
//                        easing: "easeOutExpo",
//                        duration: 400,
//                        delay: (el, i) => 33 * i * 2
//                    })
//                    .add({
//                        targets: '.ml3 .letter',
//                        scale: 0.5,
//                        opacity: 0,
//                        translateZ: 0,
//                        easing: "easeOutExpo",
//                        duration: 666,
//                        delay: 900,
//
//                    })
//                    .add({
//                        targets: '.ml3',
//                        height: 0,
//                        marginBottom: 0,
//                    })
//
//
//
//                    .add({
//                        targets: '.ml4',
//                        scale: [3, 1],
//                        opacity: [0, 1],
//                        translateZ: 0,
//                        easing: "easeOutExpo",
//                        duration: 644,
//                        delay: (el, i) => 70 * i * 2
//                    })
//                    .add({
//                        targets: '.ml4',
//                        scale: 0.5,
//                        opacity: 0,
//                        translateZ: 0,
//                        easing: "easeOutExpo",
//                        duration: 1166,
//                        delay: 200,
//
//                    })
//                    .add({
//                        targets: '.ml4',
//                        height: 0,
//                        marginBottom: 0,
//                        marginBottom: 0,
//                        delay: 500
//                    })
/////////










//
//
//                    .add({
//                        targets: '.ml7 .letter',
//                        scale: [4, 1],
//                        opacity: [0, 1],
//                        translateZ: 0,
//                        easing: "easeOutExpo",
//                        duration: 950,
//                        delay: (el, i) => 70 * i * 3
//                    })
//
//                    .add({
//                        targets: '.ml7 .letter',
//                        scale: 0.5,
//                        opacity: 0,
//                        translateZ: 0,
//                        easing: "easeOutExpo",
//                        duration: 666,
//                        height: 0,
//                    })
//                    .add({
//                        targets: '.ml7',
//                        height: 0,
//                    })
//



                    .add({
                        targets: '.slogan444 img',
                        autoplay: false,
                        easing: 'linear',
                        duration: 444,
                        delay: 1,
                        width: ["29vw", "53vw"],
                        opacity: 1,
                    })


//                    .add({
//                        targets: '.slogan444 img',
//                        autoplay: false,
//                        easing: 'linear',
//                        duration: 1,
//                        delay: 1,
//                        transition: 'none',
//
//                    })



//                    .add({
//                        targets: filesScanned,
//                        autoplay: false,
//                        count: 1000,
//                        infected: 8,
//                        easing: 'linear',
//                        round: 1,
//                        update: function () {
//                            var total = document.querySelector('.scan-count');
//                            total.innerHTML = filesScanned.count;
//
//                            var infected = document.querySelector('.infected-count');
//                            infected.innerHTML = filesScanned.infected;
//                        }
//                    })














                    .add({
                        targets: '.mainnaviBackground',
                        opacity: 1,
                        bottom: 0,
                        easing: "easeOutExpo",
                        duration: 333,
                        delay: 1
                    })


                    .add({
                        targets: '.blackBackground',
                        opacity: 1,
                        easing: "easeOutExpo",
                        duration: 555,
                        delay: 1
                    })

                    .add({
                        targets: '.mainnavi div',
                        scale: [mainnavi_div_scale],
                        visibility: 'visible',
                        opacity: 1,

                        easing: "easeOutExpo",
                        duration: 333,
                        delay: (el, i) => 70 * i * 2
                    })
                    .add({
                        targets: '#nav',

                        easing: "easeOutExpo",
                        duration: 22,
                        delay: 1,

                        complete: function (anim) {
                            var t3 = anime.timeline({

                                loop: true // Is not inherited
                            });

                            t3
                                    .add({

                                        delay: 555
                                    })
                                    .add({
                                        targets: '.mainnavi a',
                                        scale: [1, 1.07, 1],
                                        visibility: 'visible',

                                        easing: "easeOutExpo",
                                        duration: 1222,
                                        delay: 2411

                                    })
                                    .add({
                                        targets: 'footer',

                                        visibility: 'visible',
                                        opacity: 1,
                                        width: '100%',
                                        easing: "easeOutExpo",
                                        duration: 11,

                                    })





                        }


                    })

                    ;
        } catch (error) {
        }
    }, 15);



}